import "../../App.css";
import Comment from "react-spinners/PulseLoader";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { GetDecryptAccessTokenformat, GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Components/Common/Common";
import { useDispatch } from "react-redux";
import { ReGenerateToken } from "../../redux/auth/authSlice";

const Chatbot = () => {
  const [handleinput, setHandleinput] = useState([]);
  const [convertation, setConvertation] = useState([]);

  const email = GetDecryptEmailformat();

  const referenceNumber = GetDecryptReferenceNumberformat();

  const answerScroll = useRef(null);

  const dispatch = useDispatch();

  const HandleChange = (e) => {
    setHandleinput(e.target.value);
  };

  const HandleSubmit = async (e) => {
    e?.preventDefault();

    setHandleinput("");

    let previousResponse = [...convertation];

    previousResponse[previousResponse?.length - 1] = {
      ...previousResponse[previousResponse?.length - 1],
      animate: false,
    };

    previousResponse.push({ question: handleinput, answer: "", animate: true });
    setConvertation(previousResponse);

    async function query() {
      try {
        const token = GetDecryptAccessTokenformat();
        const response = await fetch("https://api.projectw.ai/api/chat/v1/huggingFaceChat", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            user_message: handleinput,
            email: email,
            reference_number: referenceNumber,
          }),
        });
        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.message,
          };
          if (answerScroll.current) {
            answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
          }

          setConvertation(previousResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.message?.data[0],
        };

        setConvertation(previousResponse);

        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }
      } catch (error) {
        toast.error(error);
      }
    }

    query().then((response) => {
      return response;
    });
  };

  const AnimatedText = ({ text }) => {
    const [displayText, setDisplayText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (currentIndex < text?.length) {
          setDisplayText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          setConvertation((prevState) => {
            let newState = [...prevState];
            newState[newState?.length - 1] = {
              ...newState[newState?.length - 1],
              animate: false,
            };
            return newState;
          });
          clearInterval(interval);
        }
      }, 10);
      return () => clearInterval(interval);
    }, [currentIndex, text]);

    return <div className="animated-text">{displayText}</div>;
  };

  const HandleClearChat = () => {
    toast.success("Chat Cleared");
    setConvertation(
      convertation.filter((ele) => {
        return;
      })
    );
  };

  const Answer = ({ answerText, animateText }) => {
    if (animateText) {
      return (
        <div style={{ color: "black" }}>
          <p>
            <AnimatedText text={answerText} />
          </p>
        </div>
      );
    }
    if (answerText) {
      return (
        <div style={{ color: "black" }}>
          <p>{answerText}</p>
        </div>
      );
    }
    return <Comment height="50" width="50" color="#4b49ac" visible={true} wrapperStyle={{}} backgroundColor="#F4442E" ariaLabel="comment-loading" wrapperClass="comment-wrapper" />;
  };

  return (
    <>
      <div className="Chat_bot_css ">
        <div className="d-flex justify-content-center">
          <h1 style={{ fontSize: "16px" }}>ChatBot</h1>
        </div>
        <div className="main-div-msgchatbot">
          <div className="input-box">
            <div className="input-group">
              <span className="input-group-text">
                <i className="fa-regular fa-message"></i>
              </span>
              <input
                type="text"
                className="form-control input_css_for_all_placeholder"
                name="text"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && handleinput?.length > 0) {
                    HandleSubmit();
                  }
                }}
                onChange={(e) => HandleChange(e)}
                value={handleinput}
                placeholder="Ask a question..."
              />
              <button className="btn btn-link" onClick={(e) => HandleSubmit(e)} disabled={handleinput?.length == 0}>
                <i className={`fa-regular fa-paper-plane   ${handleinput?.length > 0 ? "chatai_icon_css" : ""}`}></i>
              </button>
              <button className="btn btn-link" onClick={(e) => HandleClearChat(e)} disabled={convertation == ""}>
                <i className={`fa-solid fa-trash-can  ${convertation?.length > 0 ? "chatai_icon_css" : ""}`}></i>
              </button>
            </div>
          </div>
        </div>

        <div className="message_css" ref={answerScroll}>
          {convertation.map((item, index) => {
            return (
              <div key={index} style={{ width: "100%", maxWidth: "98%" }}>
                <div className="right-ms">
                  <div className="Question_css">
                    <p className="mx-3 my-2" style={{ color: "black" }}>
                      {item?.question}
                    </p>
                  </div>
                </div>

                <div className="left-ms">
                  <div className="Answer_css my-4">
                    <Answer answerText={item?.answer} animateText={item?.animate} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Chatbot;
