import React from "react";
import { useLocation } from "react-router";
const FooterSection = ({ toggle }) => {
  const location = useLocation();

  return (
    <>
      <div className={`${location?.pathname === "/socialPolicies" ? "footer_custom_css" : "footer"}`}>
        <div className="container-fluid">
          <div className="row">
            <div className={`col-lg-12 ${toggle === false ? "footer_css" : "footer_css_transition"}`}>
              <h1> WEAIU ©{new Date().getFullYear()} Created by WEAIU.</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterSection;
