import React from "react";

const Faq = () => {
  return (
    
    <div className="privacyPolicy_css">
      <h1>Frequently Asked Questions (FAQ)</h1>
      <div>
        <h2>What is ProjectW?</h2>
        <p>
          ProjectW is a platform designed to help manage APIs, billing, and provide status updates for services in real time. It offers a user-friendly interface to monitor and maintain project-related tasks.
        </p>

        <h2>How can I monitor my API status?</h2>
        <p>
          Navigate to the "ApiStatus" section from the dashboard menu. This section provides real-time updates on the availability and performance of your APIs.
        </p>

        <h2>Where can I view my billing details?</h2>
        <p>
          To view billing information, click on the "BillingStatus" section in the left-hand menu. This will show your billing history, current plan details, and payment options.
        </p>

        <h2>What should I do if I encounter an issue with the platform?</h2>
        <p>
          If you face any issues, visit our support page or contact our helpdesk. Alternatively, check the "ApiStatus" section to see if the issue is system-wide.
        </p>

        <h2>How do I update my account information?</h2>
        <p>
          Account settings can be updated by clicking on your profile in the top right corner and selecting "Account Settings." From here, you can change your password, update your email address, and configure other personal settings.
        </p>

        <h2>Is there a way to get notifications for system outages?</h2>
        <p>
          Yes, you can enable system outage notifications in your account settings. Make sure your email or phone number is updated to receive alerts.
        </p>

        <h2>What do I do if I need more API calls than my current plan allows?</h2>
        <p>
          If you need to increase your API call limit, go to the "BillingStatus" section and select "Upgrade Plan." This will show available subscription tiers and their respective features.
        </p>

        <h2>How secure is ProjectW?</h2>
        <p>
          ProjectW follows industry-standard security protocols to ensure your data is protected. We implement encryption, secure data storage, and regular security audits to maintain a safe environment for all users.
        </p>
      </div>
    </div>
  );
};

export default Faq;
