import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { bscTestnet } from "wagmi/chains";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Tawk from "./Components/Authentication/Tawk";

const config = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "88bbe34d1477226e5483e6237a6ac092",
  chains: [bscTestnet],
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider>
        <Provider store={store}>
          <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GoogleClientID}`}>
            <ToastContainer />
            <App />
            <Tawk />
          </GoogleOAuthProvider>
        </Provider>
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
);

reportWebVitals();
