import React, { useState, useEffect } from "react";
import { Button, Form, Input, Typography, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "../../App.css";
import "react-international-phone/style.css";
import { GetProfileAdmin, UploadProfilePictureAdmin } from "../../redux/auth/authSlice";
import Loader from "../../Components/Loader/Loader";
import avatar from "../../Utils/Images/userpic.png";
import { DecryptedDataAdminlocalStorage, EncryptDataAdmin } from "../../Components/Common/Common";

const { Title } = Typography;

const Profile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { profileDetailsAdmin, isLoading } = useSelector((state) => state?.counter);
  const adminDetails = {
    email: "",
    role: "",
    reference_number: "",
  };

  const [showForm, setShowForm] = useState(false);
  const [isProfilePictureUpdating, setIsProfilePictureUpdating] = useState(false);

  const referenceNumber = localStorage?.getItem("reference_number");

  useEffect(() => {
    let obj = {
      reference_number: DecryptedDataAdminlocalStorage(referenceNumber),
    };
    let encBody = EncryptDataAdmin(JSON.stringify(obj));

    let objData = {
      enc: encBody,
    };

    dispatch(GetProfileAdmin({ objData, dispatch }));
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      email: profileDetailsAdmin?.email ? profileDetailsAdmin?.email : adminDetails?.email,
      role: profileDetailsAdmin?.role ? profileDetailsAdmin?.role : adminDetails?.role,
      reference_number: profileDetailsAdmin?.reference_number ? profileDetailsAdmin?.reference_number : adminDetails?.reference_number,
    });
  }, [profileDetailsAdmin]);

  const OnFinish = (values) => {
    const file = values?.image?.[0]?.originFileObj || null;
    if (!file && !isProfilePictureUpdating) {
      delete values.image;
    }

    if (file) {
      let objforupdatepic = {
        userId: profileDetailsAdmin?._id,
        profile_image: file,
      };
      let obj = {
        reference_number: DecryptedDataAdminlocalStorage(referenceNumber),
      };
      let encBody = EncryptDataAdmin(JSON.stringify(obj));

      let objData = {
        enc: encBody,
      };

      dispatch(UploadProfilePictureAdmin({ objforupdatepic, dispatch, objData }));
    }

    setShowForm(false);
  };

  const Toggle = () => {
    setIsProfilePictureUpdating(false);
    setShowForm(true);
  };

  const OnBackButtonClick = () => {
    setShowForm(false);
  };

  const profilePictureUrl = profileDetailsAdmin?.profile_picture_url ? `${profileDetailsAdmin?.profile_picture_url}?t=${new Date().getTime()}` : avatar;

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Title className={showForm ? "text-center" : ""}>Admin Profile</Title>
      </div>
      {showForm && (
        <>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={OnBackButtonClick} style={{ marginBottom: "16px", fontSize: "15px" }}>
            Go Back
          </Button>
          <div className="profile-details">
            <Form form={form} name="basic" initialValues={{ remember: true }} layout="horizontal" className="form-size form_custom_css" autoComplete="off" onFinish={OnFinish}>
              <Form.Item
                label="Profile Picture"
                name="image"
                valuePropName="fileList"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value.length === 0) {
                        if (!isProfilePictureUpdating) return Promise.resolve();
                        return Promise.reject(new Error("Please upload a file."));
                      }
                      const isValidFileType = value[0]?.type === "image/jpeg" || value[0]?.type === "image/png";
                      if (!isValidFileType) {
                        return Promise.reject(new Error("Only JPEG/PNG files are allowed."));
                      }
                      const isValidFileSize = value[0]?.size / 1024 / 1024 < 2;
                      if (!isValidFileSize) {
                        return Promise.reject(new Error("File size must be less than 2MB."));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                getValueFromEvent={(e) => {
                  if (e && e.fileList) {
                    return e.fileList;
                  }
                  return [];
                }}
              >
                <Upload name="image" listType="picture" maxCount={1} beforeUpload={() => false}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>

              <Form.Item label="Email" name="email" rules={[{ required: true, message: "Email" }]}>
                <Input placeholder="Email" style={{ background: "#f5f7ff" }} readOnly defaultValue={adminDetails?.email} value={adminDetails?.email} />
              </Form.Item>

              <Form.Item label="Role" name="role" rules={[{ required: true, message: "Role" }]}>
                <Input placeholder="Role" style={{ background: "#f5f7ff" }} readOnly defaultValue={adminDetails?.role} value={adminDetails?.role} />
              </Form.Item>

              <Form.Item label="Reference No" name="reference_number" rules={[{ required: true, message: "Reference number" }]}>
                <Input
                  placeholder="Reference number"
                  style={{ background: "#f5f7ff" }}
                  readOnly
                  defaultValue={adminDetails?.reference_number}
                  value={adminDetails?.reference_number}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }} style={{ marginBottom: "0" }}>
                <Button type="primary" htmlType="submit" className="update-btn">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
      {!showForm && (
        <section className="profile-section">
          <div className="row">
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body custom-row-bk">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Image</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {" "}
                        <img className="rounded-circleProfile" src={profilePictureUrl} height={80} width={80} alt="Profile" />
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{profileDetailsAdmin?.email ? profileDetailsAdmin?.email : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Role</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mailto:mb-0">{profileDetailsAdmin?.role ? profileDetailsAdmin?.role : ""}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Reference number</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{profileDetailsAdmin?.reference_number ? profileDetailsAdmin?.reference_number : ""}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-9" style={{ display: "flex", alignItems: "center" }}>
                      <p className="mb-0">
                        <button className="profile-edit" onClick={() => Toggle()}>
                          <span>Edit</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Profile;
