import React, { useEffect, useState } from "react";
import NavbarSection from "./Navbar";
import Sidebar from "./Sidebar";
import FooterSection from "./Footer";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { GetDecryptAccessTokenformat, GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../../Components/Common/Common";

const UserLayout = (props) => {
  const [toggle, setToggle] = useState(false);
  const { children } = props;
  const location = useLocation();

  const HandleToggle = () => {
    setToggle(!toggle);
  };

  const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

  let email = GetDecryptEmailformat();

  let reference_number = GetDecryptReferenceNumberformat();

  let access_token = GetDecryptAccessTokenformat();

  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });

      if (email || reference_number || access_token) {
        localStorage?.removeItem("email");
        localStorage?.removeItem("access_token");
        localStorage?.removeItem("reference_number");
        localStorage?.removeItem("UserName");
        sessionStorage?.removeItem("username");
        sessionStorage?.removeItem("password");
        navigate("/");
      }
    }, 1800000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, [events]);

  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.remove("open-class");
    setTimeout(() => {
      if (toggle) {
        document.body.classList.add("open-class");
      }
    }, 250);

    if (
      !localStorage.getItem("access_token") &&
      location.pathname !== "/admin" &&
      location.pathname !== "/privacypolicy" &&
      location.pathname !== "/termsandconditions" &&
      location.pathname !== "/apistatusbilling"
    ) {
      navigate("/");
    }
  }, [toggle]);

  const pathNames = ["/", "/login", "/admin", "/signup", "/otp", "/verifyotp", "/forgotpassword", "/resetpassword", "/termsandconditions", "/privacypolicy", "/apistatusbilling"];

  if (pathNames.includes(location.pathname)) {
    return children;
  }

  return (
    <>
      <NavbarSection HandleToggle={HandleToggle} toggle={toggle} />
      <Sidebar HandleToggle={HandleToggle} toggle={toggle} />
      <section className="dasboard-all pages">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-dasboard">{children}</div>
            </div>
          </div>
        </div>
      </section>
      <FooterSection toggle={toggle} />
    </>
  );
};

export default UserLayout;
