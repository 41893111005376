import React from "react";
import "../App.css";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginHandler } from "../redux/auth/authSlice";

const GoogleAuthentication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <div className="center">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            if (credentialResponse?.clientId) {
              let data = {
                idToken: credentialResponse?.credential,
                client_type: "web",
              };
              dispatch(LoginHandler({ data, navigate, dispatch }));
            }
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          useOneTap
        />
      </div>
    </>
  );
};

export default GoogleAuthentication;
