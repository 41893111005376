import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const TermsandConditions = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("access_token");

  const HandleNav = () => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <div style={{ padding: "50px" }}>
        <div className="privacyPolicy_css">
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => HandleNav()}
            style={{
              fontSize: "15px",
              position: "absolute",
              top: "16px",
              left: "16px",
              zIndex: "99999",
            }}
          >
            Go Back
          </Button>
          <h1>Terms & Conditions</h1>
          <p>
            Thank you for choosing Project W. By accessing this app and/or website, you agree to be bound by these Terms and Conditions. Please read these Terms carefully and
            contact us if you have any questions!
          </p>

          <h2>Definitions</h2>
          <ul>
            <li>
              <b>“Project W”</b> – referred to herein as “we” or “us”
            </li>
            <li>
              <b>“Users” or “You” and “Your”</b> – refers to the person, business, or company that is the user of the Service.
            </li>
            <li>
              <b>“Services” or “Service”</b> – refers to the services provided to users by Project W via the App/Website/App.
            </li>
            <li>
              <b>“Website/App/App”</b> – refers to the internet site or any other App operated by Project W.
            </li>
          </ul>

          <h2>Using our Services</h2>
          <p>
            You may use our services if you agree with these Terms and Conditions and applicable laws. When you create your account and use certain features, you must provide us
            with accurate and complete information, and you agree to update your information to keep it accurate and complete.
          </p>
          <p>
            These Agreements constitute all the terms and conditions agreed upon between you and us and supersede any other agreements in relation to the subject matter of these
            Agreements, whether written or oral.
          </p>

          <h2>Our License to You</h2>
          <p>
            Subject to these Terms we grant you a limited, personal, non-exclusive, non-transferable, and revocable license to use our Services. You may not download content from
            our services unless you obtain our written permission to use the content. You also agree that you will create, access, and/or use only one user account. Using our
            services does not give you ownership of any intellectual property rights in our services or the content you access. This is the grant of a license, and under this
            license, you may not: modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or non-commercial); attempt to
            decompile or reverse engineer any software contained on our Website/App; remove any copyright or other proprietary notations from the materials; or transfer the
            materials to another person or “mirror” the materials on any other server. This license shall automatically terminate if you violate any of these restrictions and may
            be terminated by us at any time. Upon ending your viewing of these materials or this license, you must destroy any downloaded materials in your possession whether in
            electronic or printed format.
          </p>

          <h2>Your Content</h2>
          <p>
            In these Standard Terms and Conditions, “Your Content” means any audio, video text, images, video, or other material you choose to display on this Website/App. When you
            submit text or share content, you grant us a non-exclusive, worldwide, irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute
            it in all media. Your shared content must be your own and must not be invading any third-party’s rights, as well as being within the boundaries of both law and
            respectful manners. We reserve the right to remove any of Your Content from this Website/App at any time without notice.
          </p>

          <h2>Intellectual Property Rights</h2>
          <p>
            We respect the intellectual property of our users and other third parties and expect that you will do the same for our intellectual property and that of third parties.
            We own all the intellectual property rights and materials contained on this Website/App. You are granted a limited license only to view the material on this
            Website/App. While using our Website/App, you can access and/or use content provided by partners, other users, and/or other third parties and links to Website/Apps and
            services maintained by third parties. We cannot guarantee that such third-party content will be free of material, you may find objectionable or otherwise inappropriate
            or of malware or other contaminants that may harm your computer, mobile device, or any files therein. We refuse any responsibility or liability related to your access
            or use of such third-party content.
          </p>

          <h2>Privacy</h2>
          <p>
            We care about the privacy of our users. While we work to protect your privacy, we cannot guarantee that unauthorized third parties will not be able to defeat our
            security and privacy measures. If you believe there has been a compromise or unauthorized access to your personal account, please notify us immediately at
            info@areax.ai. We align with GDPR requirements for data protection, please notice that can exercise your rights under GDPR. For more information, please visit our
            website at www.projectw.ai
          </p>

          <h2>Technology limitations and modifications</h2>
          <p>
            We will make reasonable efforts to keep our services operational. However, technical difficulties or maintenance may result in temporary interruptions. We reserve the
            right, periodically and at any time, to temporarily or permanently modify our services’ functions and features, with or without notice, without liability to you for any
            interruption, modification, or discontinuation of our services or any function or feature thereof. You understand and agree that we have no obligation to maintain,
            support, upgrade, or update the service, or to provide all or any specific content through the Service.
          </p>

          <h2>Payments</h2>
          <p>
            We offer some of our services for a fee, you agree to pay the fees for services that you purchase, and you authorize us to charge your debit or credit card or other
            means of payment for those fees. We work with third-party payment processing partners to offer you the most convenient payment methods and to keep your payment
            information secure. For more details about the security and privacy of your payment information please check out our Privacy Policy for more details. We align with GDPR
            in relation to protection of user data. When you make a purchase, you agree not to use an invalid or unauthorized payment method. If your payment method fails and you
            still get access to the services, you agree to pay us the corresponding fees within seven (7) days of notification from us. We reserve the right to disable access to
            any services for which we have not received adequate payments.
          </p>

          <h2>No warranties</h2>
          <p>
            The services and all included content are provided on an “as is” basis without warranty of any kind, whether expressed or implied. Project W specifically disclaims any
            warranties and conditions of merchantability, fitness for a particular purpose, and non-infringement, and any warranties arising out of Project W of dealing or usage of
            trade. Project W further disclaims any liability related to your access or use of the third-party services or any related content. You acknowledge and agree that any
            access to or use of third-party services or such content is at your own risk.
          </p>

          <h2>Limitation of liability</h2>
          <p>
            To the maximum extent permitted by law, Project W shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits
            or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from: (a) your access to or use of or
            inability to access or use the services; (b) any conduct or content of any party other than the applicable Project W party, including without limitation, any
            defamatory, offensive, or illegal conduct; or (c) unauthorized access, use, or alteration of your content or information. in no event shall Project W’s aggregate
            liability for all claims related to the services exceed twenty US dollars ($20) or the total amount of fees received by Project W from you for the use of paid services
            during the past six months, whichever is greater. You acknowledge and agree that the disclaimers and limitations of liability set forth in these terms and conditions
            reflect a reasonable and fair allocation of risk between you and Project W, and that these limitations are an essential basis to Project W’s ability to make the
            services available to you on an economically feasible basis. You agree that any cause of action related to the services must commence within one (1) year after the
            cause of action accrues. otherwise, such cause of action is permanently barred.
          </p>

          <h2>Indemnification</h2>
          <p>
            You hereby fully indemnify us from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your
            breach of any of the provisions of these Terms.
          </p>

          <h2>Term and termination</h2>
          <p>
            These Terms will continue to apply to you until the termination of our services, by either you or us. We may terminate the Terms at any time, including in the event of
            your actual or suspected unauthorized use of our Service or non-compliance with the Terms. If you or we terminate the Terms, we will not refund any amounts that you
            have already paid, to the fullest extent permitted under applicable law.
          </p>

          <h2>Severability</h2>
          <p>If any provision of these Terms is invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>

          <h2>Governing Law & Jurisdiction</h2>
          <p>
            These Terms will be governed by and interpreted in accordance with the laws of United Arab Emirates and you submit to the non-exclusive jurisdiction of the state and
            federal courts located in UAE for the resolution of any disputes.
          </p>

          <h2>Changes to the Terms and Conditions</h2>
          <p>
            Occasionally we may, at our discretion, make changes to the Terms and Conditions. When we change the Terms and Conditions that we consider material, we’ll notify you.
            By continuing to use the Website/App after those changes are made, you express and acknowledge your acceptance of the changes.
          </p>

          <h2>Contact us</h2>
          <p>
            If you require any additional information or have any questions regarding these terms and conditions, please feel free to contact us by email at mailto:info@areax.ai
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsandConditions;
