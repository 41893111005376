import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import AdminLayout from "./AdminLayout";
import BillingStatusAdmin from "./BillingStatusAdmin";
import ApiStatusAdmin from "./ApiStatusAdmin";
import Faq from "./Faq";
import Login from "../../Components/Authentication/Login";
import AdminLogin from "../../Components/Authentication/AdminLogin";
import Profile from "./Profile";
import UserDetails from "./UserDetails";
import OTP from "../../Components/Authentication/OTP";
import OTPVerify from "../../Components/Authentication/VerifyOTP";
import ForgotPassword from "../../Components/Authentication/ForgotPassword";
import ResetPassword from "../../Components/Authentication/ResetPassword";
import PrivacyPolicies from "../User-panel/PrivacyPolicies";
import TermsandConditions from "../User-panel/TermsandConditions";

function App() {
  return (
    <>
      <AdminLayout>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/admin" element={<AdminLogin />}></Route>
          <Route path="/verifyotp" element={<OTPVerify />}></Route>
          <Route path="/otp" element={<OTP />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/admindashboard" element={<Dashboard />}></Route>
          <Route path="/billingstatistics" element={<BillingStatusAdmin />}></Route>
          <Route path="/apistatus" element={<ApiStatusAdmin />}></Route>
          <Route path="/apistatusbilling" element={<ApiStatusAdmin />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicies />}></Route>
          <Route path="/termsandconditions" element={<TermsandConditions />}></Route>
          <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
          <Route path="/UserDetails" element={<UserDetails />}></Route>
          <Route path="/resetpassword" element={<ResetPassword />}></Route>
        </Routes>
      </AdminLayout>
    </>
  );
}

export default App;
