import { Button, Input, Space, Table, DatePicker, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTransactionHistory } from "../../../redux/auth/authSlice";
import Highlighter from "react-highlight-words";
import moment from "moment";

const { RangePicker } = DatePicker;

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");

  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { getUserWalletDetails, transactionHistory } = useSelector((state) => state?.counter);
  const { Option } = Select;
  const [selectedType, setSelectedType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  const searchInput = useRef(null);

  useEffect(() => {
    if (transactionHistory?.length) {
      const list = transactionHistory.map((item) => {
        if (item.txnMethod === "addMint" || item.txnMethod === "addpost") {
          return {
            ...item,
            txnMethod: "addMint(automint)",
            txnHash: item?.hash || item?.txnHash,
            addpost: "Auto Add Post",
          };
        }
        return { ...item, txnHash: item?.hash || item?.txnHash };
      });
      setTransactionHistoryData(list);
      setFilteredData(list);
    }
  }, [transactionHistory]);

  useEffect(() => {
    if (getUserWalletDetails) {
      setAddress(getUserWalletDetails);
    }
  }, [getUserWalletDetails]);

  useEffect(() => {
    if (address) {
      dispatch(GetTransactionHistory({ address }));
    }
  }, [address, dispatch]);

  useEffect(() => {
    let data = [...transactionHistoryData];

    if (selectedType) {
      data = data.filter((item) => item.txnMethod === selectedType);
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      const [start, end] = dateRange;
      const startDate = moment(start).startOf("day");
      const endDate = moment(end).endOf("day");

      data = data.filter((item) => {
        const itemDate = moment(item.created_at.split("T")[0]);

        return itemDate.isValid() && itemDate.isBetween(startDate, endDate, "day", "[]");
      });
    }

    setFilteredData(data);
  }, [selectedType, dateRange, transactionHistoryData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDateChange = (dates, dateStrings) => {
    setDateRange(dates);
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<i className="fa-solid fa-magnifying-glass"></i>}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <i className="fa-solid fa-magnifying-glass"></i>,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Hash",
      dataIndex: "txnHash",
      key: "txnHash",
      render: (txnHash) => (
        <a href={`https://testnet.bscscan.com/tx/${txnHash}`} target="_blank" rel="noopener noreferrer">
          {txnHash}
        </a>
      ),
    },
    {
      title: "Type",
      dataIndex: "txnMethod",
      key: "txnMethod",
      ...getColumnSearchProps("txnMethod"),
    },
    {
      title: "created_at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
      ...getColumnSearchProps("created_at"),
    },
  ];

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        <RangePicker onChange={handleDateChange} format="YYYY-MM-DD" value={dateRange} />
        <Select defaultValue="Select Transaction Type" style={{ width: 200 }} onChange={handleTypeChange}>
          <Option value="">All</Option>
          <Option value="addMint(automint)">addMint(automint)</Option>
          <Option value="Auto Add Post">Auto Add Post</Option>
          <Option value="Manual Add Post">Manual Add Post</Option>
          <Option value="deposit">Deposit</Option>
          <Option value="transfer">Transfer</Option>
          <Option value="Auto Add Multiple Post">Auto Add Multiple Post</Option>
        </Select>
      </Space>
      {filteredData?.length > 0 ? <Table rowKey="txnHash" columns={columns} scroll={{ x: 800 }} dataSource={filteredData} /> : <p className="no-dt">No transactions found.</p>}
    </>
  );
};

export default TransactionHistory;
