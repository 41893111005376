import React, { useEffect, useState } from "react";
import GetCookies from "../../../Utils/helpers/GetCookies";
import { InstagramLoginDetails } from "../../../redux/auth/authSlice";
import {
  GetDecryptEmailformat,
  GetDecryptInstagramEID,
  GetDecryptInstagramUID,
  GetDecryptReferenceNumberformat,
  SetEncrypytInstagramPID,
  SetEncrypytInstagramUID,
} from "../../Common/Common";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function SetupCredentialsForm() {
  const [errors, setErrors] = useState({});
  const [togglePassword, setTogglePassword] = useState(false);
  const [details, setDetails] = useState({ username: "", password: "", rememberMe: false });

  const dispatch = useDispatch();
  const email = GetDecryptEmailformat();
  const referenceNumber = GetDecryptReferenceNumberformat();
  const { TokenID, walletBalanceUser, getUserWalletDetails } = useSelector((state) => state?.counter);

  const HandleChange = (name, value) => {
    setDetails((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: undefined }));
  };

  const ValidationForMdal = () => {
    let errors = {};

    if (!details?.username) {
      errors.username = "This field is required";
    }
    if (!details?.password) {
      errors.password = "This field is required";
    }

    setErrors(errors);

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  const HandleViewInsite = (e) => {
    e.preventDefault();
    const email1 = GetCookies("InsEID");

    const password1 = GetCookies("InsPID");

    if (details?.rememberMe && !email1 && !password1) {
      const days = 15;
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = date.toUTCString();

      const encryptusername = SetEncrypytInstagramUID(details?.username);
      const encryptpassword = SetEncrypytInstagramPID(details?.password);

      document.cookie = `InsEID=${encryptusername}; expires=${expires}; path=/`;
      document.cookie = `InsPID=${encryptpassword}; expires=${expires}; path=/`;
      document.cookie = `InsRM=${details?.rememberMe}; expires=${expires}; path=/`;
    } else if (!details?.rememberMe && email1 && password1) {
      document.cookie = `InsEID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      document.cookie = `InsPID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      document.cookie = `InsRM=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }

    let validData = ValidationForMdal();

    if (validData) {
      return;
    } else {
      const obj = {
        reference_number: referenceNumber,
        username: details?.username,
        password: details?.password,
      };
      dispatch(InstagramLoginDetails({ obj, dispatch, TokenID, getUserWalletDetails, walletBalanceUser }));
    }
  };

  useEffect(() => {
    const rememberMe = GetCookies("InsRM");
    if (rememberMe) {
      const email1 = GetCookies("InsEID");
      const password2 = GetCookies("InsPID");

      let username = GetDecryptInstagramEID(email1);
      let password = GetDecryptInstagramUID(password2);

      setDetails((prevState) => ({ ...prevState, username, password, rememberMe }));
    }
  }, []);

  return (
    <div className="card siz-card">
      <div>
        <h1 style={{ fontSize: "16px" }}> Instagram User Credentials form</h1>
      </div>
      <div className="p-3 my-2">
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            Username <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control input_css_for_all_placeholder"
            value={details?.username}
            onChange={(e) => HandleChange("username", e.target.value)}
            id="username"
            placeholder="Enter your instagram username"
            autoComplete="new-username"
          />
          <p style={{ color: "red" }}>{errors?.username}</p>
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="form-label">
            Password <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type={togglePassword ? "text" : "password"}
            className="form-control input_css_for_all_placeholder"
            value={details?.password}
            onChange={(e) => HandleChange("password", e.target.value)}
            id="password"
            autoComplete="new-password"
            placeholder="Enter your instagram password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                HandleViewInsite(e);
              }
            }}
          />
          <p style={{ color: "red" }}>{errors?.password}</p>
          <div className="pass-toggle1">
            <FontAwesomeIcon icon={togglePassword ? faEyeSlash : faEye} className="password-toggle-icon" onClick={() => setTogglePassword(!togglePassword)} />
          </div>
        </div>

        <div className="mb-3 ">
          <input type="checkbox" id="rememberMe" onClick={(e) => HandleChange("rememberMe", e.target.checked)} checked={details.rememberMe} />{" "}
          <span className="mx-1">Remember me</span>
        </div>

        <button className="btn btn-primary w-100" onClick={(e) => HandleViewInsite(e)}>
          Submit
        </button>
      </div>
    </div>
  );
}
