import React, { useEffect, useState } from "react";

const Home = () => {
  return (
    <>
      <h1 className="text-center">Home Page</h1>
    </>
  );
};

export default Home;
