import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { ResetPasswordApi } from "../../redux/auth/authSlice";

const { Title, Text } = Typography;

const ResetPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onFinish = (values) => {
    let obj = {
      email: sessionStorage?.getItem("email"),
      reference_number: sessionStorage?.getItem("reference_number"),
      otp: values?.otp,
      password: values?.newPassword,
      confirm_password: values?.confirmPassword,
    };
    dispatch(ResetPasswordApi({ obj, dispatch, navigate }));
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please input your new password!"));
    }
    if (value.length < 6) {
      return Promise.reject(new Error("Password must be at least 6 characters long."));
    }
    if (/^\d+$/.test(value)) {
      return Promise.reject(new Error("Password cannot contain numbers only."));
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(new Error("Password must contain at least one uppercase letter."));
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });

  return (
    <>
      <div
        className="signuppage mt-5 bg-light text-black text-v p-5 resp_otp_css otp_custom_css"
        style={{ maxWidth: "400px", margin: "auto", padding: "20px", textAlign: "center" }}
      >
        <LockOutlined style={{ fontSize: "50px", marginBottom: "20px" }} />

        <Title level={4}>Reset Your Password</Title>

        <Text>Please enter the OTP you received and set your new password below.</Text>

        <Form name="reset_password" layout="vertical" onFinish={onFinish} style={{ marginTop: "20px" }}>
          <Form.Item name="otp" label="OTP" rules={[{ required: true, message: "Please input the OTP sent to your email" }]}>
            <Input placeholder="Enter OTP" size="large" />
          </Form.Item>

          <Form.Item name="newPassword" label="New Password" rules={[{ required: true, validator: validatePassword }]}>
            <Input.Password placeholder="New Password" size="large" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["newPassword"]}
            rules={[{ required: true, message: "Please confirm your password!" }, validateConfirmPassword]}
          >
            <Input.Password placeholder="Confirm Password" size="large" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block size="large">
              Reset Password
            </Button>
          </Form.Item>
        </Form>

        <Link to="/" style={{ display: "block", marginBottom: "20px", textDecoration: "none", fontSize: "15px", color: "black" }}>
          Back to Login
        </Link>
      </div>

      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("/")}
        style={{
          fontSize: "15px",
          position: "absolute",
          top: "16px",
          left: "16px",
          zIndex: "99999",
        }}
      >
        Go Back
      </Button>
    </>
  );
};

export default ResetPassword;
