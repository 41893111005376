import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Form, Typography, List, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SearchByMailWords } from "../../redux/auth/authSlice";
import { GetDecryptEmailformat } from "../../Components/Common/Common";

const { Title, Text } = Typography;

const Mail = () => {
  const dispatch = useDispatch();
  const [mailonchange, setMailonchange] = useState("");
  const [error, setError] = useState("");
  const { wordsData } = useSelector((state) => state?.counter);

  const email = GetDecryptEmailformat();

  const handleSearch = () => {
    const validationErrors = validate();
    if (validationErrors) {
      setError(validationErrors);
      return;
    }

    setMailonchange("");
    const obj = {
      email: email,
      search_words: mailonchange,
    };

    dispatch(SearchByMailWords({ obj, dispatch }));
  };

  const validate = () => {
    if (mailonchange.trim() === "") {
      return "*Required";
    }
    return null;
  };

  return (
    <div style={{ padding: "15px" }}>
      <Title level={3}>Search by a keyword in the email subject</Title>

      <Form layout="inline" onFinish={handleSearch}>
        <Form.Item validateStatus={error ? "error" : ""} help={error}>
          <Input
            placeholder="Search posts..."
            value={mailonchange}
            onChange={(e) => {
              setMailonchange(e.target.value);
              setError("");
            }}
            suffix={<SearchOutlined />}
            onPressEnter={handleSearch}
            style={{ width: 300 }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>

      <div style={{ marginTop: "24px" }}>
        <Title level={4}>Search Results</Title>
        <List
          bordered
          dataSource={wordsData}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <Text>{item.split(/&nbsp;|,/).join(", ")}</Text>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default Mail;
