import { React, useEffect } from "react";

const Tawk = () => {
  useEffect(() => {
    const tawkToScript = document.createElement("script");
    tawkToScript.src = "https://embed.tawk.to/66fa5521e5982d6c7bb68359/1i90tp3i2";
    tawkToScript.async = true;
    tawkToScript.setAttribute("crossorigin", "*");

    document.body.appendChild(tawkToScript);

    return () => {
      document.body.removeChild(tawkToScript);
    };
  }, []);

  return null;
};

export default Tawk;
