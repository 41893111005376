import React, { useEffect } from "react";
import { Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GetApiStatus } from "../../redux/auth/authSlice";

const ApiStatus = () => {
  const { ApiStatus } = useSelector((state) => state.counter);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetApiStatus({ dispatch }));
  }, []);

  const columns = [
    {
      title: "Services ",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (name) => <a>{name}</a>,
    },
    {
      title: "Status ",
      key: "status",
      align: "center",
      render: (text, record) => <span>{record.statuscode === 200 ? <Tag color="green">● Active</Tag> : <Tag color="red">● Not Active</Tag>}</span>,
    },
    {
      title: "History",
      dataIndex: "statuscode",
      key: "statuscode",
      align: "center",
      render: (statuscode, a) => {
        const allowedStatusCodes = [200];
        if (allowedStatusCodes.includes(statuscode)) {
          return <span>{`${a.name} services are currently available`}</span>;
        }
        return <span>{`${a.name} services are temporarily unavailable`}</span>;
      },
    },
  ];

  const isAnyServiceAvailable = ApiStatus?.some((item) => item.status === "Active");

  return (
    <>
      <div className="billing-container">
        <h2 className={`main-txt ${isAnyServiceAvailable ? "text-success" : "text-danger"}`}>ProjectW is {isAnyServiceAvailable ? "Up" : "Disabled"}</h2>
        <p>Welcome to the ProjectW Status Page. We will make every effort to provide updates promptly.</p>
        <Table columns={columns} dataSource={ApiStatus} pagination={false} style={{ textAlign: "center" }} />
      </div>
    </>
  );
};

export default ApiStatus;
