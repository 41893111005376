import React, { useEffect } from "react";

const Dashboard = () => {

  return (
    <>
      <h1 className="text-center">User Dashboard</h1>

    </>
  );
};

export default Dashboard;
