import React, { useEffect, useState } from "react";
import GoogleAuthentication from "../../Components/GoogleAuthentication";
import { Link, useNavigate } from "react-router-dom";
import { GetProfile, LoginUser } from "../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../../App.css";
import Loader from "../Loader/Loader";
import ProjectWlogo from "../../../src/Utils/Images/ProjectWlogo.svg";
import Aidashboardimg from "../../../src/Utils/Images/dashboardimg.jpg";

import { GetDecryptEID, GetDecryptEmailformat, GetDecryptReferenceNumberformat, GetDecryptUID, SetEncrypytPID, SetEncrypytUID } from "../Common/Common";

const Login = () => {
  const [error, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [captchaQuestion, setCaptchaQuestion] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [userCaptchaAnswer, setUserCaptchaAnswer] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const generateCaptcha = () => {
    let num1 = Math.floor(Math.random() * 10) + 1;
    let num2 = Math.floor(Math.random() * 10) + 1;
    const operations = ["+", "-", "*", "/"];
    const operation = operations[Math.floor(Math.random() * operations.length)];

    let question;
    let answer;

    switch (operation) {
      case "+":
        question = `What is ${num1} + ${num2}?`;
        answer = num1 + num2;
        break;
      case "-":
        question = `What is ${num1} - ${num2}?`;
        answer = num1 - num2;
        break;
      case "*":
        question = `What is ${num1} * ${num2}?`;
        answer = num1 * num2;
        break;
      case "/":
        num2 = num2 === 0 ? 1 : num2;
        num1 = num1 - (num1 % num2);
        question = `What is ${num1} / ${num2}?`;
        answer = num1 / num2;
        break;
      default:
        break;
    }

    setCaptchaQuestion(question);
    setCaptchaAnswer(answer);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleCaptchaChange = (e) => {
    const value = e.target.value;
    if (/^-?\d*$/.test(value)) {
      setUserCaptchaAnswer(value);
      setCaptchaError("");
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    const key = String.fromCharCode(charCode);
    if ((charCode < 48 || charCode > 57) && (charCode !== 45 || userCaptchaAnswer.length > 0)) {
      e.preventDefault();
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.counter);

  const Validation = () => {
    const errors = {};
    if (loginData?.email === "" && loginData?.email?.length == 0) {
      errors.email = "This field is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(loginData?.email)) {
      errors.email = "Invalid email address";
    }
    if (loginData?.password === "" && loginData?.password?.length == 0) {
      errors.password = "This field is required";
    } else if (/^\d+$/.test(loginData?.password)) {
      errors.password = "Password cannot contain numbers only.";
    } else if (loginData?.password?.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
    }
    setErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  const HandleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setErrors({ ...error, [e.target.name]: undefined });
  };

  function getCookie(name) {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(";");

    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  }

  const HandleLogin = (e) => {
    e.preventDefault();

    const email = getCookie("EID");
    const password = getCookie("PID");

    if (rememberMe && !email && !password) {
      const days = 15;
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = date.toUTCString();

      const encryptemail = SetEncrypytUID(loginData?.email);
      const encryptpassword = SetEncrypytPID(loginData?.password);

      document.cookie = `EID=${encryptemail}; expires=${expires}; path=/`;
      document.cookie = `PID=${encryptpassword}; expires=${expires}; path=/`;
      document.cookie = `RM=${rememberMe}; expires=${expires}; path=/`;
    } else if (!rememberMe && email && password) {
      document.cookie = `EID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      document.cookie = `PID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      document.cookie = `RM=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }

    let data = Validation();
    if (data) {
      return;
    }
    if (parseInt(userCaptchaAnswer) !== captchaAnswer) {
      setCaptchaError("CAPTCHA is incorrect.");
      return;
    }

    dispatch(LoginUser({ loginData, navigate, dispatch }));
  };

  const TogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const HandleChecked = (event) => {
    setRememberMe(event?.target?.checked);
  };

  const { userRole } = useSelector((auth) => auth.counter);

  useEffect(() => {
    const rememberMe = getCookie("RM");
    if (rememberMe) {
      const email1 = getCookie("EID");
      const password2 = getCookie("PID");
      let email = GetDecryptEID(email1);
      let password = GetDecryptUID(password2);
      setLoginData((prevState) => ({ ...prevState, email, password }));
      setRememberMe(rememberMe);
    }
    const obj = {
      email: GetDecryptEmailformat() || localStorage.getItem("email"),
      reference_number: GetDecryptReferenceNumberformat() || localStorage.getItem("reference_number"),
    };
    dispatch(GetProfile({ dispatch, obj }));
    if (localStorage.getItem("access_token") && userRole === "USER") {
      navigate("/dashboard");
    } else if (localStorage.getItem("access_token") && userRole === "admin") {
      navigate("/admindashboard");
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <header className="header">
        <div className="logo-container">
          <img src={ProjectWlogo} alt="Logo" className="logo" />
        </div>
      </header>
      <section className="background-radial-gradient overflow-hidden">
        <div className="container px-4 py-3 px-md-5  text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: "10", boxShadow: "10px 10px 5px white" }}>
              <img src={Aidashboardimg} height={320} width={500} style={{ borderRadius: "10px" }} className="resp_login_img" />
              <h1 className="my-5 display-5 fw-bold ls-tight" style={{ color: "#4b49ac" }}>
                Future of <br />
                <span style={{ color: "#4b49ac" }}>Artificial Intelligence</span>
              </h1>
              <p className="mb-4 opacity-70" style={{ color: "#3e3e3e" }}>
                Artificial intelligence refers to computer systems that can perform tasks commonly associated with human cognitive functions — such as interpreting speech, playing
                games and identifying patterns.
              </p>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
              <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
              <div className="card bg-glass bg_login_css ">
                <div className="card-body px-4 py-5 px-md-5">
                  <form>
                    <div className="form-outline mb-4 Label_css">
                      <label className="form-label" htmlFor="form3Example3">
                        <h4>
                          <b style={{ color: "#4b49ac" }}>Login</b>
                        </h4>
                      </label>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form3Example3">
                        Email address <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        style={{ border: "none" }}
                        type="email"
                        name="email"
                        className="form-control input_css_for_all_placeholder"
                        value={loginData?.email}
                        onChange={(e) => HandleChange(e)}
                      />
                      <p style={{ color: "red" }}>{error.email}</p>
                    </div>

                    <div className="form-outline mb-1 position-relative">
                      <label className="form-label" htmlFor="form3Example4">
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        style={{ border: "none" }}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control input_css_for_all_placeholder"
                        value={loginData?.password}
                        onChange={(e) => HandleChange(e)}
                      />
                      <div className="pass-toggle">
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="password-toggle-icon" onClick={TogglePasswordVisibility} />
                      </div>
                      <p style={{ color: "red" }}>{error.password}</p>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label">Please verify you are a human</label>
                      <div className="captcha-question mb-2">{captchaQuestion}</div>
                      <input
                        className="form-control"
                        value={userCaptchaAnswer}
                        onChange={handleCaptchaChange}
                        onKeyPress={handleKeyPress}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            HandleLogin(e);
                          }
                        }}
                      />
                      <p style={{ color: "red" }}>{captchaError}</p>
                    </div>

                    <div className="form-outline  mb-2 position-relative">
                      <input type="checkbox" onClick={(e) => HandleChecked(e)} checked={rememberMe} /> <span className="mx-1">Remember me</span>
                    </div>

                    <button type="submit" style={{ background: "#f3797e", border: "none" }} className="btn btn-primary btn-block mb-4 btn-100" onClick={(e) => HandleLogin(e)}>
                      Sign in
                    </button>
                    <div className="text-center">
                      <p>
                        Don't have an account ?{" "}
                        <Link to="/signup" style={{ textDecoration: "none", color: "#4b49ac" }}>
                          Sign up
                        </Link>
                      </p>
                      <p>or sign up with</p>
                      <button type="button" className="btn btn-link btn-floating mx-1">
                        <GoogleAuthentication />
                        <div className="my-3">
                          <Link to="/forgotpassword" style={{ textDecoration: "none" }}>
                            <p style={{ fontSize: "14px" }}>Forgotten your password?</p>
                          </Link>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
