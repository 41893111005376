import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "../../style/admin.css";
import { AdminLogin, GetProfileAdmin } from "../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { DecryptedDataAdminlocalStorage, EncryptDataAdmin } from "../Common/Common";
import Loader from "../../Components/Loader/Loader";

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const { userRole, isLoading } = useSelector((auth) => auth.counter);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    let obj = {
      email: values?.email,
      password: values?.password,
    };

    let encBody = EncryptDataAdmin(JSON.stringify(obj));

    let objData = {
      enc: encBody,
    };

    if (parseInt(userCaptchaAnswer) !== captchaAnswer) {
      setCaptchaError("CAPTCHA is incorrect.");
      return;
    }

    dispatch(AdminLogin({ objData, dispatch, navigate }));
  };

  useEffect(() => {
    let obj = {
      reference_number: DecryptedDataAdminlocalStorage(localStorage.getItem("reference_number")),
    };

    let encBody = EncryptDataAdmin(JSON.stringify(obj));

    let objData = {
      enc: encBody,
    };

    dispatch(GetProfileAdmin({ objData, dispatch }));
    if (localStorage.getItem("access_token") && userRole === "USER") {
      navigate("/dashboard");
    } else if (localStorage.getItem("access_token") && userRole === "admin") {
      navigate("/admindashboard");
    }
  }, []);

  const [captchaQuestion, setCaptchaQuestion] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [userCaptchaAnswer, setUserCaptchaAnswer] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const generateCaptcha = () => {
    let num1 = Math.floor(Math.random() * 10) + 1;
    let num2 = Math.floor(Math.random() * 10) + 1;
    const operations = ["+", "-", "*", "/"];
    const operation = operations[Math.floor(Math.random() * operations.length)];

    let question;
    let answer;

    switch (operation) {
      case "+":
        question = `What is ${num1} + ${num2}?`;
        answer = num1 + num2;
        break;
      case "-":
        question = `What is ${num1} - ${num2}?`;
        answer = num1 - num2;
        break;
      case "*":
        question = `What is ${num1} * ${num2}?`;
        answer = num1 * num2;
        break;
      case "/":
        num2 = num2 === 0 ? 1 : num2;
        num1 = num1 - (num1 % num2);
        question = `What is ${num1} / ${num2}?`;
        answer = num1 / num2;
        break;
      default:
        break;
    }

    setCaptchaQuestion(question);
    setCaptchaAnswer(answer);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleCaptchaChange = (e) => {
    const value = e.target.value;
    if (/^-?\d*$/.test(value)) {
      setUserCaptchaAnswer(value);
      setCaptchaError("");
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div
        className="signuppage mt-5 bg-light text-black text-v p-5 resp_otp_css otp_custom_css admin_login_css"
        style={{ maxWidth: "400px", margin: "auto", padding: "20px", textAlign: "center" }}
      >
        <Title level={4}>Admin Login</Title>

        <Form name="trouble_logging_in" layout="vertical" onFinish={onFinish} style={{ marginTop: "20px" }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              {
                type: "email",
                message: "Invalid email address",
              },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
              {
                min: 6,
                message: "Password must be at least 6 characters long",
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          <Form.Item label="Please verify you are a human">
            <Text>{captchaQuestion}</Text>
            <Input value={userCaptchaAnswer} onChange={handleCaptchaChange} placeholder="Enter CAPTCHA answer" />
            <Text type="danger">{captchaError}</Text>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block size="large">
              Submit
            </Button>
          </Form.Item>
        </Form>

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Text>OR</Text>
        </div>

        <Link
          to="/"
          style={{
            display: "block",
            marginBottom: "20px",
            textDecoration: "none",
            fontSize: "15px",
            color: "black",
          }}
        >
          Back to Login
        </Link>
      </div>

      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("/")}
        style={{
          fontSize: "15px",
          position: "absolute",
          top: "16px",
          left: "16px",
          zIndex: "99999",
        }}
      >
        Go Back
      </Button>
    </>
  );
};

export default ForgotPassword;
