import React from "react";
import "../../../App.css";
import { Link, useLocation } from "react-router-dom";

import { FaHouseUser, FaCaretLeft } from "react-icons/fa";

import ProjectWlogo from "../../../Utils/Images/ProjectWlogo.svg";

const Sidebar = ({ HandleToggle }) => {
  const pathName = useLocation();

  return (
    <>
      <div className="side-bar">
        <a className="abs-bk" onClick={HandleToggle}>
          <FaCaretLeft className="icon" />
        </a>
        <div className="logo-sec">
          <img className="w-100 logo-top" src="images/logo.png" alt="" />
          <h1
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "serif",
              fontWeight: "600",
            }}
          >
            <img src={ProjectWlogo} alt="Logo" className="logo" />
          </h1>
        </div>
        <div className="link-div">
          <ul className="link-ul">
            <li>
              <Link to="/admindashboard" className={pathName.pathname === "/admindashboard" ? "active" : ""}>
                <FaHouseUser className="icons-svg" />
                Dashboard
              </Link>
            </li>

            <li>
              <Link to="/billingstatistics" className={pathName.pathname === "/billingstatistics" ? "active" : ""}>
                <FaHouseUser className="icons-svg" />
                BillingStatus
              </Link>
            </li>

            <li>
              <Link to="/apistatus" className={pathName.pathname === "/apistatus" ? "active" : ""}>
                <FaHouseUser className="icons-svg" />
                ApiStatus
              </Link>
            </li>

            <li>
              <Link to="/UserDetails" className={pathName.pathname === "/UserDetails" ? "active" : ""}>
                <FaHouseUser className="icons-svg" />
                User Details
              </Link>
            </li>

            <li>
              <Link to="/faq" className={pathName.pathname === "/faq" ? "active" : ""}>
                <FaHouseUser className="icons-svg" />
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
