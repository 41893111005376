import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { ForgotPasswordEmailOtp } from "../../redux/auth/authSlice";

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const onFinish = (values) => {
    const obj = {
      email: values?.email,
      handshake_value: values?.email,
    };
    dispatch(ForgotPasswordEmailOtp({ obj, dispatch, navigate }));
  };

  return (
    <>
      <div
        className="signuppage mt-5 bg-light text-black text-v p-5 resp_otp_css otp_custom_css"
        style={{ maxWidth: "400px", margin: "auto", padding: "20px", textAlign: "center" }}
      >
        <LockOutlined style={{ fontSize: "50px", marginBottom: "20px" }} />

        <Title level={4}>Trouble with logging in?</Title>

        <Text>Enter your email address and we'll send you a link to get back into your account.</Text>

        <Form name="trouble_logging_in" layout="vertical" onFinish={onFinish} style={{ marginTop: "20px" }}>
          <Form.Item name="email" rules={[{ required: true, message: "Please input your email" }]}>
            <Input placeholder="Email address" size="large" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block size="large">
              Send Login Link
            </Button>
          </Form.Item>
        </Form>

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Text>OR</Text>
        </div>

        <Link to="/signup" style={{ display: "block", marginBottom: "20px", textDecoration: "none", fontSize: "15px" }}>
          Create New Account
        </Link>

        <Link to="/" style={{ display: "block", marginBottom: "20px", textDecoration: "none", fontSize: "15px", color: "black" }}>
          Back to Login
        </Link>
      </div>

      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("/")}
        style={{
          fontSize: "15px",
          position: "absolute",
          top: "16px",
          left: "16px",
          zIndex: "99999",
        }}
      >
        Go Back
      </Button>
    </>
  );
};

export default ForgotPassword;
